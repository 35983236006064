<template>
  <div
    ref="compare"
    :class="[
      'compare-container',
      reportFullscreen.status ? 'change-height' : '',
      isCollapsed & this.reportFullscreen.status ? 'change-width' : ''
    ]">
    <div v-if="!noData && !hideHeader" class="header">
      Compare Rating vs Recommendation %
      <b-button class="toggle-button" @click="toggleReportFullscreen(5)">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="expand-icon" width="16" height="16" fill="#c2c7de" viewBox="0 0 16 16" v-if="!fullscreen" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z" class="cls-1"/></svg>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#c2c7de" width="16" height="16" viewBox="0 0 16 16" v-else="" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z" class="cls-1"/></svg>
        </div>
      </b-button>
    </div>
    <div :class="[`compare-rating-container`]" :style="cssVars">
      <svg id="compare-rating-recommendation" v-if="!noData"></svg>
      <no-data-container-vue v-else />
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import tooltip from '../../util/tooltip'
import { mapState, mapActions } from 'vuex'
import { itemColors } from '@/util/util.js'
import NoDataContainerVue from '../Common/NoDataContainer/NoDataContainer.vue'
import { fullScreenMode } from '../../util/util'

/** This component is used to render a Kano Analysis chart. It's currently used on the **CSAT Drivers** page */
export default {
  name: 'KanoChart',
  components: {
    NoDataContainerVue
  },
  props: {
    /**
     * This indicates whether or not the minimum data required to render the chart is available
     */
    noData: {
      type: Boolean
    },
    /**
     * This is the data used to render the solid bubbles. If no product is selected, it renders the bubbles using the data of all products, otherwise it renders the bubbles based on the product of a single product.
     */
    data: {
      type: Array
    },
    /**
     * This is used to hide the title/header of the Kano Chart
     */
    hideHeader: {
      type: Boolean,
      default: false
    },
    /**
     * This contains the legend text for the x and y axes
     */
    legendsText: {
      type: Object
    },
    domains: {
      type: Object,
      default: null
    },
    /**
     * This array contains the x and y values of the avgSatisfaction and avgCorrelationCoeff lines.
     */
    axesArray: {
      type: Array,
      default: null
    },
    /**
     * This array contains the data used to render the outlined bubbles. If no product is selected, then no bubbles are rendered. If a product is selected, then it renders the bubbles using the data of all products excluding the selected one.
     */
    secondaryData: {
      type: Array,
      default: null
    },
    /**
     * This array contains the data of all products. It is always available, because the y-axis coordinates of all bubbles are taken from this array
     */
    totalData: {
      type: Array,
      default: null
    },
    /**
     * This prop controls the zoom behaviour of the chart
     */
    zoom: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      observer: null
    }
  },
  computed: {
    // ...mapState('report', ['compareRatingOverRecommendation']),
    ...mapState('filters', ['selectedProducts']),
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    ...mapState('winLoss', ['wlSelectedProductLeft', 'wlSelectedProductRight']),
    ...mapState('csatdriver', ['sentimentScoreDataSingle', 'sentimentScoreDataTotal']),
    fullscreen () {
      return (
        this.reportFullscreen.id === this.id && this.reportFullscreen.status
      )
    },
    cssVars () {
      return {
        '--container-height': this.hideHeader ? '100%' : 'calc(100% - 36px)'
      }
    }
  },
  watch: {
    // compareRatingOverRecommendation () {
    //   if (this.compareRatingOverRecommendation.length) {
    //     this.renderChart()
    //   }
    // }
    axesArray () {
    },
    data () {
      if (this.data.length) {
        this.renderChart()
      }
    },
    secondaryData () {
      this.renderChart()
    },
    noData () {
      if (this.data.length) {
        setTimeout(() => {
          this.renderChart()
        }, 20)
      }
    },
    totalData () {
      // console.log(this.totalData)
    }
  },
  mounted () {
    if (this.data.length) {
      this.renderChart()
    }
    this.resizeWindow()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('filters', ['updateSelectedProducts']),
    ...mapActions('common', [
      'toggleReportFullscreen',
      'resetReportFullscreen'
    ]),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.data.length) {
          if (d3.select('.compare-rating-container').node() != null) {
            this.renderChart()
          }
        }
      })
      this.observer.observe(this.$refs.compare)
    },
    /**
     * This method is used to render the Kano Chart
     */
    renderChart () {
      // Attr value variables
      const xAxisPadding = 0.5
      const yAxisPadding = 0.07
      const bubbleRadius = 12
      const yOffset = 44
      const metaLabelXOffset = 6
      const quadLabelRect = 100

      const mainData = this.data
      const secondaryData = this.secondaryData
      const totalData = this.totalData
      const axesArray = this.axesArray
      // const averageCorrelationCoeff =
      //   this.axesArray?.filter((d) => d.is_standard === true)[0]
      //     ?.overall_rating || null
      // const averageOfAvgSatisfaction =
      //   this.axesArray?.filter((d) => d.is_standard === false)[0]
      //     ?.overall_rating || null
      const averageCorrelationCoeff =
        this.axesArray?.find((d) => d.is_standard === true)
          ?.overall_rating || null
      const averageOfAvgSatisfaction =
        this.axesArray?.find((d) => d.is_standard === false)
          ?.overall_rating || null
      d3.selectAll('#compare-rating-recommendation > *').remove()

      const margin = { top: 20, right: 70, bottom: 70, left: 50 }
      const width = d3
        .select('.compare-rating-container')
        .node()
        .getBoundingClientRect().width
      const height = d3
        .select('.compare-rating-container')
        .node()
        .getBoundingClientRect().height
      const zoom = d3
        .zoom()
        .scaleExtent([1, 8])
        .translateExtent([
          [0, 0],
          [width, height]
        ])
        .extent([
          [0, 0],
          [width, height]
        ])
        .on('zoom', zoomed)

      const svg = d3
        .select('#compare-rating-recommendation')
        .attr('width', width)
        .attr('height', height)
        // .call(zoom)
        // .on('dblclick.zoom', function (d) {
        //   svg.transition().duration(500).call(zoom.transform, d3.zoomIdentity)
        // })
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
      const x = d3
        .scaleLinear()
        .domain(
          this.domains
            ? [
                d3.min([
                  [...this.data, ...this.secondaryData].map((data) => data.avgSatisfaction),
                  parseFloat(averageOfAvgSatisfaction)
                ]) - xAxisPadding,
                d3.max([
                  [...this.data, ...this.secondaryData].map((data) => data.avgSatisfaction),
                  parseFloat(averageOfAvgSatisfaction)
                ]) + xAxisPadding
              ]
            : [
                d3.min([...this.data, ...this.secondaryData].map((data) => data.avgSatisfaction)) - xAxisPadding,
                d3.max([...this.data, ...this.secondaryData].map((data) => data.avgSatisfaction)) + xAxisPadding
              ]
        )
        .range([0, width - margin.right])
      const y = d3
        .scaleLinear()
        .domain(
          this.domains
            ? [
                d3.min([
                  [...this.totalData, ...this.totalData].map((data) => data.correlationCoef),
                  parseFloat(averageCorrelationCoeff)
                ]) - yAxisPadding,
                d3.max([
                  [...this.totalData, ...this.totalData].map((data) => data.correlationCoef),
                  parseFloat(averageCorrelationCoeff)
                ]) + yAxisPadding
              ]
            : [
                d3.min([...this.totalData, ...this.totalData].map((data) => data.correlationCoef)) - yAxisPadding,
                d3.max([...this.totalData, ...this.totalData].map((data) => data.correlationCoef)) + yAxisPadding
              ]
        )
        // The 22 here (22 from the top, 22 from the bottom is the amount of height that needs to be reduced from the y-axis drawing area, to make sure the bubbles do not render over/under the 4 quadrant labels.)
        .range([height - margin.bottom - yOffset / 2, yOffset / 2])

      const zoomContainer = svg.append('rect')
        .attr('width', width - margin.right)
        .attr('height', height - margin.bottom - yOffset)
        .attr('y', yOffset / 2)
        .attr('fill', 'transparent')
        .style('pointer-events', 'all')
        .attr('transfrom', 'translate(' + margin.left + ',' + margin.top + ')')

      if (this.zoom) {
        zoomContainer.call(zoom)
        .on('dblclick.zoom', function (d) {
          zoomContainer.transition().duration(500).call(zoom.transform, d3.zoomIdentity)
        })
      }

      const defs = svg.append('defs')
      defs
        .append('SVG:clipPath')
        .attr('id', 'clip')
        .append('SVG:rect')
        .attr('x', 0)
        .attr('y', yOffset / 2)
        .attr('width', width - margin.right)
        .attr('height', height - margin.bottom - yOffset)

      const xAxis = d3.axisBottom(x)
      const yAxis = d3.axisLeft(y)
      const xAxisPlot = svg
        .append('g')
        .attr('transform', `translate(0, ${height - margin.bottom})`)
        .call(xAxis)
      xAxisPlot.selectAll('.tick').remove()
      xAxisPlot.selectAll('.domain').remove()
      // xAxisPlot.selectAll('.tick').each(function (d) {
      //   d3.select(this).append('line').attr('id', 'line').attr('y2', -height + margin.bottom)
      // })

      const borderRect = svg.append('rect')
        .attr('width', width - margin.right)
        .attr('height', height - margin.bottom - yOffset)
        .attr('y', yOffset / 2)
        .attr('stroke', 'var(--axis-color)')
        .attr('stroke-width', '0.3px')
        .attr('fill', 'none')

      const yAxisPlot = svg.append('g').call(yAxis)
      yAxisPlot.selectAll('.tick').remove()
      yAxisPlot.selectAll('.domain').remove()

      const metaLabels = svg.append('g').attr('class', 'metaLabels')

      // Left meta label
      metaLabels.append('text')
        .attr('x', -metaLabelXOffset)
        .attr('y', margin.top + 15)
        .attr('text-anchor', 'end')
        .attr('class', 'metaLabel1')
        .text('High')
      // Right meta label
      metaLabels.append('text')
        .attr('x', width - margin.right - metaLabelXOffset)
        .attr('y', height - margin.bottom - 5)
        .attr('text-anchor', 'end')
        .attr('class', 'metaLabel3')
        .text('High')
      // Left meta label
      metaLabels.append('text')
        .attr('x', -metaLabelXOffset)
        .attr('y', height - margin.bottom - 28)
        .attr('text-anchor', 'end')
        .attr('class', 'metaLabel2')
        .text('Low')
      // Right meta label
      metaLabels.append('text')
        .attr('x', metaLabelXOffset)
        .attr('y', height - margin.bottom - 5)
        .attr('class', 'metaLabel4')
        .text('Low')

      const chartBody = svg.append('g').attr('clip-path', 'url(#clip)')

      let correlationCoeffAxis = null
      let avgSatisfactionAxis = null

      if (axesArray) {
        correlationCoeffAxis = chartBody
          // .select('#vgparent')
          .append('line')
          .attr('x1', function (d) {
            return x(x.domain()[0])
          })
          .attr('x2', function (d) {
            return x(x.domain()[1])
          })
          .attr('y1', function (d) {
            return y(Number(averageCorrelationCoeff).toFixed(4))
          })
          .attr('y2', function (d) {
            return y(Number(averageCorrelationCoeff).toFixed(4))
          })
          .attr('d', function (d) {
            return Number(averageCorrelationCoeff).toFixed(3)
          })
          .attr('stroke', '#dd1d1b')
          .attr('stroke-width', '2px')
          .style('stroke-dasharray', '6, 3')
          .on('mouseover', function (event) {
            const d = { name: 'Average Correlation Coefficient', avg: this.getAttribute('d') }
            lineTooltipFunction(event, d, 'in')
          })
          .on('mousemove', function (event) {
            const d = { name: 'Average Correlation Coefficient', avg: this.getAttribute('d') }
            lineTooltipFunction(event, d, 'in')
          })
          .on('mouseout', function (event) {
            const d = { name: 'Average Correlation Coefficient', avg: this.getAttribute('d') }
            lineTooltipFunction(event, d, 'out')
          })
      }

      // if (axesArray) {
      avgSatisfactionAxis = chartBody
        // .select('#vgparent')
        .append('line')
        .attr('x1', function (d) {
          return x(averageOfAvgSatisfaction)
        })
        .attr('x2', function (d) {
          return x(averageOfAvgSatisfaction)
        })
        .attr('y1', function (d) {
          return y(y.domain()[0])
        })
        .attr('y2', function (d) {
          return y(y.domain()[1])
        })
        .attr('d', function (d) {
          return Number(averageOfAvgSatisfaction).toFixed(3)
        })
        .attr('stroke', '#dd1d1b')
        .attr('stroke-width', '2px')
        .style('stroke-dasharray', '6, 3')
        .on('mouseover', function (event) {
          const d = {
            name: 'Average Satisfaction',
            avg: this.getAttribute('d')
          }
          lineTooltipFunction(event, d, 'in')
        })
        .on('mousemove', function (event) {
          const d = {
            name: 'Average Satisfaction',
            avg: this.getAttribute('d')
          }
          lineTooltipFunction(event, d, 'in')
        })
        .on('mouseout', function (event) {
          const d = {
            name: 'Average Satisfaction',
            avg: this.getAttribute('d')
          }
          lineTooltipFunction(event, d, 'out')
        })

      function zoomed (event, d) {
        const newXScale = event.transform.rescaleX(x)
        const newYScale = event.transform.rescaleY(y)

        xAxisPlot.call(xAxis.scale(newXScale))
        yAxisPlot.call(yAxis.scale(newYScale))

        xAxisPlot.selectAll('.tick').remove()
        xAxisPlot.selectAll('.domain').remove()
        yAxisPlot.selectAll('.tick').remove()
        yAxisPlot.selectAll('.domain').remove()

        // yAxisPlot.selectAll('.tick').each(function (d) {
        //   d3.select(this).select('#line').remove()

        //   // d3.select(this)
        //   //   .append('line')
        //   //   .attr('id', 'line')
        //   //   .attr('x2', width - margin.right)
        // })
        svg
          .selectAll('.tick')
          .selectAll('text')
          .attr('fill', 'var(--axis-color)')
          .attr('font-family', 'Quicksand, sans-serif')
        svg
          .selectAll('.tick')
          .selectAll('line')
          .attr('stroke', 'var(--chart-background-dotted-line-color)')
        svg
          .selectAll('.domain')
          .attr('stroke', 'var(--chart-background-dotted-line-color)')

        chartBody.selectAll('.filledCircle').data(mainData)
          .join()
          .attr('cx', (d) => newXScale(d.avgSatisfaction))
        chartBody.selectAll('.filledCircle').data(totalData)
          .join()
          .attr('cy', (d) => newYScale(d.correlationCoef))
        chartBody.selectAll('.kanoOther').data(secondaryData)
          .join()
          .attr('cx', (d) => newXScale(d.avgSatisfaction))
        chartBody.selectAll('.kanoOther').data(totalData)
          .join()
          .attr('cy', (d) => newYScale(d.correlationCoef))

        // quad1Label
        //   .select('text')
        //   .attr('x', (width - newXScale(averageOfAvgSatisfaction)) / 2 + newXScale(averageOfAvgSatisfaction))
        //   .attr('y', 15)

        // quad2Label
        //   .select('text')
        //   .attr('x', newXScale(averageOfAvgSatisfaction) / 2)
        //   .attr('y', 15)

        // quad3Label
        //   .select('text')
        //   .attr('x', newXScale(averageOfAvgSatisfaction) / 2)
        //   .attr('y', height - 75)

        // quad4Label
        //   .append('text')
        //   .attr('x', (width - newXScale(averageOfAvgSatisfaction)) / 2 + newXScale(averageOfAvgSatisfaction))
        //   .attr('y', height - 75)

        if (axesArray) {
          correlationCoeffAxis.attr('y1', newYScale(averageCorrelationCoeff))
          correlationCoeffAxis.attr('y2', newYScale(averageCorrelationCoeff))
          avgSatisfactionAxis.attr('x1', newXScale(averageOfAvgSatisfaction))
          avgSatisfactionAxis.attr('x2', newXScale(averageOfAvgSatisfaction))
        }
      }
      svg
        .selectAll('.tick')
        .selectAll('text')
        .attr('fill', 'var(--axis-color)')
        .attr('font-family', 'Quicksand, sans-serif')
      svg
        .selectAll('.tick')
        .selectAll('line')
        .attr('stroke', 'var(--chart-background-dotted-line-color)')
      svg
        .selectAll('.domain')
        .attr('stroke', 'var(--chart-background-dotted-line-color)')

      /** This method renders the tooltip on the dashed lines/axes */
      function lineTooltipFunction (event, d, type) {
        let obj = {}
        if (d.name === 'Average Correlation Coefficient') {
          obj = {
            'Average Correlation Coefficient': d.avg
          }
        } else {
          obj = {
            'Average Satisfaction': d.avg
          }
        }
        tooltip(event, obj, type)
      }

      /** This method controls the data displayed in the tooltip when the cursor is hovered over a bubble */
      function tooltipFun (event, d, type) {
        let obj = {}
        obj = {
          '': d.topicName
          // 'Feature Maturity': Number(d.avgSatisfaction).toFixed(4),
          // 'Product Acceptance': Number(d.correlationCoef).toFixed(4)
        }
        tooltip(event, obj, type)
        // const tooltipElem = document.querySelector('.custom-tooltip')
        // tooltipElem.classList.add('transparent-tooltip')
      }

      /** This method is used to highlight a bubble and its counterpart (if available) when the cursor is hovered over it */
      function highlightCounterpart (event, data, current) {
        chartBody.selectAll('circle')
          .style('opacity', (d) => {
            if (d.topicName !== current.topicName) {
              return '0.1'
            }
          })
          .attr('transform-box', 'fill-box')
        // .style('transform', (d) => {
        //   if (d.topicName === current.topicName) {
        //     return 'translate(50%, 50%) scale(1.2) translate(-50%, -50%)'
        //   }
        // })
      }

      /** This method is used to reset the opacity of all bubbles once the cursor leaves a highlighted bubble */
      function resetHighlight (data) {
        chartBody.selectAll('circle')
          .style('opacity', 1)
      }

      chartBody
        .append('g')
        .selectAll('dot')
        .data(this.data)
        .join('circle')
        .attr('class', 'filledCircle')
        .attr('cx', (d) => x(d.avgSatisfaction))
        .attr('r', bubbleRadius)
        .attr('fill', (d) => {
          const color = itemColors(
            this.data
              .map((data) => data.topicName)
              .indexOf(d.topicName)
          )
          return color
        })
        .style('opacity', '1')
        .attr('stroke', 'none')
        .style('pointer-events', 'all')
        .on('click', (event, d) => {
          if (this.reportFullscreen.status) {
            this.resetReportFullscreen()
            fullScreenMode(document, 'off')
          }
          const data = this.selectedProducts.filter(
            ({ name }) => name === d.topicName
          )
          if (data.length) {
            this.updateSelectedProducts([data[0]])
          }
          if (this.currentModule === 'voe') {
            this.$router.push({ name: 'voeComments' })
          }
          if (this.currentModule === 'voc') {
            this.$router.push({ name: 'comments' })
          }

          tooltip(event, null, 'out')
        })
        .on('mouseover', (event, d) => {
          highlightCounterpart(event, this.data, d)
          tooltipFun(event, d, 'in')
        })
        .on('mousemove', (event, d) => {
          highlightCounterpart(event, this.data, d)
          tooltipFun(event, d, 'in')
        })
        .on('mouseout', (event, d) => {
          resetHighlight(this.data)
          tooltipFun(event, d, 'out')
        })

      chartBody.selectAll('.filledCircle')
        .data(this.totalData)
        .attr('cy', (d) => y(d.correlationCoef))

      if (this.secondaryData.length) {
        chartBody
          .append('g')
          .selectAll('dot')
          .data(this.secondaryData)
          .join('circle')
          .attr('class', 'kanoOther')
          .attr('cx', (d) => x(d.avgSatisfaction))
          .attr('cy', (d) => y(d.correlationCoef))
          .attr('r', bubbleRadius)
          .attr('fill', 'transparent')
          .attr('stroke', (d) => {
            const color = itemColors(
              this.secondaryData
                .map((data) => data.topicName)
                .indexOf(d.topicName)
            )
            return color
          })
          .style('opacity', '1')
          .style('pointer-events', 'all')
          .on('click', (event, d) => {
            if (this.reportFullscreen.status) {
              this.resetReportFullscreen()
              fullScreenMode(document, 'off')
            }
            const data = this.selectedProducts.filter(
              ({ name }) => name === d.topicName
            )
            if (data.length) {
              this.updateSelectedProducts([data[0]])
            }
            if (this.currentModule === 'voe') {
              this.$router.push({ name: 'voeComments' })
            }
            if (this.currentModule === 'voc') {
              this.$router.push({ name: 'comments' })
            }
            tooltip(event, null, 'out')
          })
          .on('mouseover', (event, d) => {
            highlightCounterpart(event, this.data, d)
            tooltipFun(event, d, 'in')
          })
          .on('mousemove', (event, d) => {
            highlightCounterpart(event, this.data, d)
            tooltipFun(event, d, 'in')
          })
          .on('mouseout', (event, d) => {
            resetHighlight(this.data)
            tooltipFun(event, d, 'out')
          })

        chartBody.selectAll('.kanoOther')
          .data(this.totalData)
          .attr('cy', (d) => y(d.correlationCoef))
      }

      const quad1Label = svg.append('g').attr('class', 'quadrantLabel')

      quad1Label
        .append('text')
        .attr('x', (width - x(averageOfAvgSatisfaction)) / 2 + x(averageOfAvgSatisfaction))
        .attr('y', 15)
        .attr('class', 'quadrantLabelText')
        .text('ESSENTIAL')
        .attr('text-anchor', 'middle')

      const quad2Label = svg.append('g').attr('class', 'quadrantLabel')

      quad2Label
        .append('text')
        .attr('x', x(averageOfAvgSatisfaction) / 2)
        .attr('y', 15)
        .attr('class', 'quadrantLabelText')
        .text('CONSPICUOUS')
        .attr('text-anchor', 'middle')

      const quad3Label = svg.append('g').attr('class', 'quadrantLabel')

      quad3Label
        .append('text')
        .attr('x', x(averageOfAvgSatisfaction) / 2)
        .attr('y', height - 75)
        .attr('class', 'quadrantLabelText')
        .text('DISCRETIONARY')
        .attr('text-anchor', 'middle')

      const quad4Label = svg.append('g').attr('class', 'quadrantLabel')

      quad4Label
        .append('text')
        .attr('x', (width - x(averageOfAvgSatisfaction)) / 2 + x(averageOfAvgSatisfaction))
        .attr('y', height - 75)
        .attr('class', 'quadrantLabelText')
        .text('INDIFFERENT')
        .attr('text-anchor', 'middle')

      svg.selectAll('.quadrantLabel')
        .insert('rect', 'text')
        .attr('x', function () {
          const nextElemX = this.nextSibling.getAttribute('x')
          const bbox = this.nextSibling.getBBox()
          return nextElemX - (bbox.width + 20) / 2
          // return nextElemX - quadLabelRect / 2
        })
        .attr('y', function () {
          const nextElemY = this.nextSibling.getAttribute('y')
          const bbox = this.nextSibling.getBBox()
          const rectHeight = bbox.height + 6
          // 5 is the remaining offset required to get the label rects perfectly lying on the border of the chart
          return nextElemY - (rectHeight) / 2 - 5
        })
        .attr('fill', 'var(--inava-pink)')
        .attr('width', function () {
          const bbox = this.nextSibling.getBBox()
          return bbox.width + 20
          // return quadLabelRect
        })
        .attr('height', function () {
          const bbox = this.nextSibling.getBBox()
          const rectHeight = bbox.height + 6
          return rectHeight
        })
        .attr('rx', 2)
      // }

      const legends = svg.append('g')

      legends
        .append('text')
        // .attr('transform', `translate(-35,${height / 2})rotate(270)`)
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - margin.left)
        .attr('x', 0 - height / 2 + margin.top)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('stroke', 'none')
        .attr('font-size', '12px')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('fill', '#888888')
        .attr('stroke-width', '1px')
        .text(this.legendsText.y)
      legends
        .append('text')
        .attr(
          'transform',
          'translate(' + width / 2 + ' ,' + (height - 30) + ')'
        )
        .attr('dx', '-2em')
        .style('text-anchor', 'middle')
        .attr('stroke', 'none')
        .attr('font-size', '12px')
        .attr('font-family', 'Quicksand, sans-serif')
        .attr('fill', '#888888')
        .attr('stroke-width', '1px')
        .text(this.legendsText.x)
    }
  }
}
</script>

<style lang="scss" scoped>
.compare-container {
  height: 60vh;
  min-height: 250px;
  padding: 10px 20px;
  transition: width 0.3s ease-out;
  .compare-rating-container {
    display: block;
    width: 100%;
    height: var(--container-height); // calc(100% - 36px);
  }
  .header {
    color: var(--secondary-text-color);
    display: flex;
    width: 100%;
    opacity: 1;
    font-size: 18px;
    font-family: Quicksand;
    height: 36px;
    align-items: center;
    .toggle-button {
      margin-left: auto;
      background-color: transparent;
      outline: none;
      border: none;
      width: 35px;
      height: 35px;
      div {
        display: flex;
      }
    }
  }
}
.change-height {
  height: calc(100vh - 15px - var(--page-tracking-height));
  width: calc(100vw - 290px);
}
.change-width {
  width: calc(100vw - 100px);
}
::v-deep .quadrantLabelText {
  fill: white;
  font-size: 14px;
  // font-family: 'Quicksand, sans-serif';
}

::v-deep .metaLabels text {
  font-size: 10px;
  fill: var(--secondary-text-color);
}
</style>

<template>
    <div v-click-outside="onClick">
    <slot></slot>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

/** This component is a wrapper for all dropdowns. It handles the closing functionality for the dropdowns  */
export default {
  name: 'DropdownWrap',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: ['onClickOutside'],
  methods: {
    onClick (event) {
      this.$emit('onClickOutside', event)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
